// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  
  firebase: {
    apiKey: "AIzaSyCnY6kOntifA4YTrn8W4I-UL-t66_IZfyI",
    authDomain: "hrms-shikuyaa.firebaseapp.com",
    projectId: "hrms-shikuyaa",
    storageBucket: "hrms-shikuyaa.appspot.com",
    messagingSenderId: "629575253570",
    appId: "1:629575253570:web:165835fb158dd8562c37db",
    measurementId: "G-PW0C7057ZS",
    appVersion1: require('../../package.json').version + '-dev',
    appVersion2: require('../../package.json').version,
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
