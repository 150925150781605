import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Department } from 'src/app/Models/department';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { EmployeeRoleMap } from 'src/app/Models/employeerolemap';
import { Roledetails } from 'src/app/Models/roledetails';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-addemployeerolemap',
  templateUrl: './addemployeerolemap.component.html',
  styleUrls: ['./addemployeerolemap.component.css']
})

export class AddemployeerolemapComponent implements OnInit {
  @Input() drawerClose: Function;
  // @Input() data: ClusterMaster;
  @Input()
  data: EmployeeRoleMap = new EmployeeRoleMap();
  @Input()
  empdata: EmployeeMaster;
  isSpinning = false
  @Input() listOfData: EmployeeRoleMap[] = [];
  OPEN_TIME
  CLOSE_TIME
  // @Input() OPEN_TIME2
  // @Input() CLOSE_TIME2
  // fKey = ""
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')
  // fileDataLOGO_URL: File = null
  // folderName = "departmentIcon"
  @Input() DAYS = false;
  isOk = true;
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  emailpattern =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  mobpattern = /^[6-9]\d{9}$/;
  passwordVisible = false;

  @Input() role: any[] = [];
  // reportinghead:EmployeeMaster[]=[];
  dept: any[] = [];
  DEPARTMENT_ID: any[] = []
  ROLE_ID = 0
  // designation:Designationmaster[]=[];
  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) {
  }

  ngOnInit() {
    // this.roleinfo();
    this.department();
  }

  // Branch Info
  roleinfo() {
    this.api.getAllRoles2(this.cookie.get('roleId'), Number(this.cookie.get('userId'))).subscribe(data => {
      this.role = data['data'];

    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  department() {
    this.api.getAllDepartments(0, 0, 'NAME', 'ASC', ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      this.dept = data['data'];

    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  //// Only number
  // omit(event:any) {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;
  // }

  close(): void {
    // this.OPEN_TIME2 = null
    // this.CLOSE_TIME2 = null
    this.drawerClose();
  }

  save(addNew: boolean): void {
    this.isSpinning = false;
    this.isOk = true;
    this.data.EMPLOYEE_ID = this.empdata.ID;
    this.data.STATUS = true;

    if (this.data.ROLE_ID == undefined || this.data.ROLE_ID == 0) {
      this.isOk = false;
      this.message.error('Please Select Role', '');

    } else if ((this.data.ROLE_ID == 6 || this.data.ROLE_ID == 4) && (this.DEPARTMENT_ID == undefined || this.DEPARTMENT_ID.length == 0)) {
      this.isOk = false;
      this.message.error('Please Select Department', '');
    }

    if (this.isOk) {
      this.isSpinning = true;
      var d = [];

      if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length > 0) {
        for (var i = 0; i < this.DEPARTMENT_ID.length; i++) {
          d.push({
            CLIENT_ID: 1,
            EMPLOYEE_ID: this.empdata.ID,
            DEPARTMENT_ID: this.DEPARTMENT_ID[i],
            STATUS: true
          })
        }
      }

      console.log(d);

      this.api.createEmpRoleMap(this.data).subscribe((successCode) => {
        // this.api.createEmpRoleMap1(this.data).subscribe((successCode) => {
        if (successCode.code == '200') {
          if (this.data.ROLE_ID != 6 && this.data.ROLE_ID != 4) {
            this.drawerClose();
            this.DEPARTMENT_ID = [];
            this.message.success('Information Created Successfully', '');
            this.isSpinning = false;

          } else {
            if (this.data.ROLE_ID == 6) {
              this.api.createEmpRoleMapBulk(d, this.empdata.ID).subscribe((successCode) => {
                if (successCode.code == '200') {
                  this.message.success('Information Created Successfully', '');

                  this.drawerClose();
                  this.DEPARTMENT_ID = [];
                  this.isSpinning = false;

                } else {
                  this.message.error('Information Creation Failed', '');
                  this.isSpinning = false;
                }
              });
            }

            if (this.data.ROLE_ID == 4) {
              this.api.tickdeskSupportUserMapping(d, this.empdata.ID).subscribe((successCode) => {
                if (successCode.code == '200') {
                  this.message.success('Information Created Successfully', '');

                  this.drawerClose();
                  this.DEPARTMENT_ID = [];
                  this.isSpinning = false;

                } else {
                  this.message.error('Information Creation Failed', '');
                  this.isSpinning = false;
                }
              });
            }
          }

        } else {
          this.message.error('Information Creation Failed', '');
          this.isSpinning = false;
        }
      });
    }
  }
}
