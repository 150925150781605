export class EmployeeMaster {
    ID: number = 0;
    EMPLOYEE_CODE: string = '';
    NAME: string = '';
    EMAIL_ID: string;
    MOBILE_NUMBER: number;
    DESIGNATION_ID: any;
    BRANCH_ID: any;
    DEPARTMENT_ID: any;
    REPORTING_HEAD_ID: any;
    TEMPORARY_HEAD_ID: any;
    PASSWORD: any;
    STATUS: boolean = true;
    SEQUENCE_NO: number = 0;
    ORG_ID = 0;

}

