import { Component, OnInit, Input } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { DatePipe, NgForOf } from '@angular/common';
import { ApiService } from 'src/app/Service/api.service';
import { UpdateAttendanceRegister } from 'src/app/Models/UpdateAttendanceRegister';
import { NgForm } from '@angular/forms';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-update-attendance-register',
  templateUrl: './update-attendance-register.component.html',
  styleUrls: ['./update-attendance-register.component.css']
})

export class UpdateAttendanceRegisterComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: UpdateAttendanceRegister;
  isSpinning = false;
  dateFormat = "yyyy-MMM-dd";
  empId: number;
  status: any;

  // month = new Date().getMonth().toString() + (new Date().getMonth() + 1).toString()
  // year = new Date().getFullYear()
  // MONTH: any = this.month;
  // MONTH1: any = new Date()
  // YEAR :any= this.year

  // today = new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString() + "-" + new Date().getDate().toString();


  // today2 = new Date();
  startValue:any

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() {

    this.startValue = new Date(
      new Date().getFullYear() + "-" + (new Date().getMonth() + 1)
      // this.today2.getFullYear() + '-' + (this.today2.getMonth() + 1)
    );
    console.log('this.startValue',this.startValue); 
   }


  date = null;
  isOk = false;
 
  disabledStartDate2 = (current: Date): boolean =>
    differenceInCalendarDays(current, this.startValue) > -1;


  save(httpForm: NgForm) {

    this.isOk = true;

    if (this.data.DATE == null || this.data.DATE == "") {
      this.isOk = false
      this.message.error('Please Enter Date.', "")
    }
    else if (this.data.STATUS == null || this.data.STATUS.trim() == "") {
      this.isOk = false
      this.message.error('Please Enter Type.', "")
    }

    this.isSpinning = false;

    if (this.isOk) {

      if (this.data.EMP_ID) {
        this.data.DATE = this.datePipe.transform(this.data.DATE, 'yyyy-MM-dd');

        this.empId = this.data.EMP_ID;
        console.log(this.empId, "empId")

        this.api.updateAttendanceRegister(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Status Updated Successfully", "");
            console.log(this.data, "data");
            this.drawerClose();
            this.reset(httpForm);
            this.isSpinning = false;

          } else {
            this.message.error("Status Updation Failed", "");
            this.isSpinning = false;
          }
        });
      }
      else {
        this.message.error("Please Fill All Required Fields", "");
        this.isSpinning = false;
      }
    }
  }


  // close(): void {
  //   this.drawerClose();
  // }

  close(httpForm: NgForm) {
    // this.isSpinning=true;
    this.reset(httpForm);
    this.drawerClose();
  }

  reset(httpForm: NgForm) {
    // this.isSpinning = true;
    httpForm.reset();
  }
}
