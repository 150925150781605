import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';
import { AddemployeemasterComponent } from '../addemployeemaster/addemployeemaster.component';

@Component({
  selector: 'app-employeemasterlist',
  templateUrl: './employeemasterlist.component.html',
  styleUrls: ['./employeemasterlist.component.css']
})

export class EmployeemasterlistComponent implements OnInit {
  formTitle = "Manage Employees";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  @Input() dataList2: any[] = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  orgId = this.cookie.get('orgId');

  columns: string[][] = [["NAME", "Employee Name"], ["EMAIL_ID", "Email"],
  ["MOBILE_NUMBER", "Mobile Number"], ["DEPARTMENT_NAME", "Department"], ["BRANCH_NAME", "Branch"], ["DESIGNATION_NAME", "Designation"],
  ["EMPLOYEE_REPORTING_HEAD_NAME", "Reporting Head"],
  ["EMPLOYEE_TEMPRARY_HEAD_NAME", "Temporary Head"], ["EMPLOYEE_CODE", "Employee Code"]]

  columns1: string[][] = [["NAME", "Employee Name"], ["EMAIL_ID", "Email"],
  ["MOBILE_NUMBER", "Mobile Number"], ["DEPARTMENT_NAME", "Department"], ["BRANCH_NAME", "Branch"], ["DESIGNATION_NAME", "Designation"],
  ["EMPLOYEE_REPORTING_HEAD_NAME", "Reporting Head"],
  ["EMPLOYEE_TEMPRARY_HEAD_NAME", "Temporary Head"], ["EMPLOYEE_CODE", "Employee Code"], ["SEQUENCE_NO", "Sequence no"]]

  drawerVisible: boolean;
  drawerVisible2: boolean;
  drawerTitle: string;
  drawerData: EmployeeMaster = new EmployeeMaster();
  applicationId = Number(this.cookie.get('applicationId'))
  drawerVisible1: boolean;
  listOfData: EmployeeMaster[] = [];
  OPEN_TIME2 = null;
  CLOSE_TIME2 = null;
  DAYS = false;
  table2 = 0;
  dataList3 = [];
  reportinghead = [];

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false) {
    var filter = "";

    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);
    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    if (likeQuery)
      filter += likeQuery;

    this.api.getAllemployeeMaster(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + ' AND  ORG_ID =' + this.orgId + ' AND ID !=' + Number(this.cookie.get('userId'))).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

      if (this.totalRecords == 0) {
        data.SEQUENCE_NO = 1;

      } else {
        data.SEQUENCE_NO = this.dataList[this.dataList.length - 1]['SEQUENCE_NO'] + 1
      }

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  add(): void {
    this.drawerData = new EmployeeMaster();
    this.drawerTitle = "Create New Employee";

    this.api.getAllemployeeMaster(0, 0, '', '', ' AND  ID in(SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID = ' + this.orgId + ' AND ROLE_ID<>2)   AND STATUS=1  ').subscribe(data => {
      this.reportinghead = data['data'];
    }, err => { });

    this.api.getAllemployeeMaster(1, 1, 'SEQUENCE_NO', 'desc', '' + ' AND  ORG_ID =' + this.orgId).subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQUENCE_NO = 1;

      } else {
        this.drawerData.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
      }
    }, err => {
      console.log(err);
    })

    this.drawerVisible = true;
  }

  @ViewChild(AddemployeemasterComponent, { static: false }) AddemployeemasterComponentVar: AddemployeemasterComponent;

  edit(data: EmployeeMaster): void {
    this.drawerTitle = "Update Employee Information";
    this.drawerData = Object.assign({}, data);

    if (this.drawerData.DEPARTMENT_ID == 0)
      this.drawerData.DEPARTMENT_ID = 0;

    if (this.drawerData.BRANCH_ID == 0)
      this.drawerData.BRANCH_ID = 0;

    if (this.drawerData.DESIGNATION_ID == 0)
      this.drawerData.DESIGNATION_ID = 0;

    if (this.drawerData.REPORTING_HEAD_ID == 0)
      this.drawerData.REPORTING_HEAD_ID = 0;

    if (this.drawerData.TEMPORARY_HEAD_ID == 0)
      this.drawerData.TEMPORARY_HEAD_ID = 0;

    this.DAYS = false;
    var d = '';

    if (data.ID != undefined && data.ID > 0) {
      d = ' AND ID !=' + data.ID;
    }

    this.api.getAllemployeeMaster(0, 0, '', '', ' AND STATUS=1  AND ORG_ID=' + this.orgId + d).subscribe(data => {
      this.reportinghead = data['data'];

    }, err => {
      console.log(err);
    });

    this.AddemployeemasterComponentVar.getallorg2(data.ID);
    this.drawerVisible = true;
  }

  map(data) {
    this.drawerTitle = "Map Roles";
    this.drawerData = Object.assign({}, data);

    this.api.getAllEmpRoleMap(0, 0, 'ID', 'ASC', ' AND EMPLOYEE_ID=' + data.ID).subscribe(data => {
      this.dataList3 = data['data'];
      this.drawerVisible2 = true;
    });
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.search();
    this.drawerVisible1 = false;
  }

  drawerClose2(): void {
    this.search();
    this.drawerVisible2 = false;
  }

  drawerTitle1: string;
  drawerData2: string[];
  drawerData1: EmployeeMaster = new EmployeeMaster();

  expensesMap(data: EmployeeMaster) {
    this.api.getAllEmployeeExpenseHead(data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.drawerData2 = data['data'];
      }

    }, err => {
      console.log(err);
    });

    this.drawerTitle1 = "Expense Heads for " + data.NAME + "";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisible1 = true;
  }

  drawerTitleEmployeeCollege: string;
  drawerVisibleEmployeeCollege: boolean;
  drawerDataEmployeeColleges: string[];

  mapCollege(data: EmployeeMaster) {
    this.api.getAllEmployeeColleges(data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.drawerDataEmployeeColleges = data['data'];
      }

    }, err => {
      console.log(err);
    });

    this.drawerTitleEmployeeCollege = "Location for " + data.NAME + "";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisibleEmployeeCollege = true;
  }

  drawerCloseCollege(): void {
    this.search();
    this.drawerVisibleEmployeeCollege = false;
  }

  get closeCallbackCollege() {
    return this.drawerCloseCollege.bind(this);
  }

  clearDeviceID(data: EmployeeMaster) {
    this.api.clearDeviceID(data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.message.success("Device ID Cleared Successfully", "");
      }

    }, err => {
      console.log(err);
    });
  }

  cancel(): void { }

  onEnterKeyPress() {
    document.getElementById("searchbtn").focus();
  }

  onActiveStatusChange(data: EmployeeMaster, status) {
    data.STATUS = status;

    this.api.updateemployeeMaster(data).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Active Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Active Status", "");

      this.search();
    });
  }
}