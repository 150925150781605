import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  userName = this.cookie.get('userName');
  emailId = this.cookie.get('emailId');
  currentMonth: string = "";
  currentYear: number;
  EOM_Name: string = "";
  EOM_Dept: string = "";
  EOM_Designation: string = "";
  EOM_Branch: string = "";
  EOM_Image: string = undefined;
  isEOMLoading: boolean = false;

  constructor(private cookie: CookieService, private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getEOM();
    this.findMonthAndYear(new Date().getMonth(), new Date().getFullYear());
  }

  findMonthAndYear(month: number, year: number) {
    let tempMonth: number;
    if (month == 0) {
      tempMonth = 12;
      this.currentYear = year - 1;

    } else {
      tempMonth = month;
      this.currentYear = year;
    }

    this.currentMonth = this.getMonth(tempMonth);
  }

  getMonth(currentMonth: number) {
    if (currentMonth == 1)
      return "January";

    else if (currentMonth == 2)
      return "February";

    else if (currentMonth == 3)
      return "March";

    else if (currentMonth == 4)
      return "March";

    else if (currentMonth == 5)
      return "May";

    else if (currentMonth == 6)
      return "June";

    else if (currentMonth == 7)
      return "July";

    else if (currentMonth == 8)
      return "August";

    else if (currentMonth == 9)
      return "September";

    else if (currentMonth == 10)
      return "October";

    else if (currentMonth == 11)
      return "November";

    else if (currentMonth == 12)
      return "December";
  }

  getEOM() {
    this.isEOMLoading = true;

    this.api.getEmployeeOfTheMonth(0, 0, "", "", "").subscribe(data => {
      if (data["code"] == 200) {
        this.isEOMLoading = false;
        this.EOM_Name = data["EMPLOYEE_OF_THE_MONTH"][0]["NAME"];
        this.EOM_Dept = data["EMPLOYEE_OF_THE_MONTH"][0]["DEPARTMENT_NAME"];
        this.EOM_Designation = data["EMPLOYEE_OF_THE_MONTH"][0]["DESIGNATION_NAME"];
        this.EOM_Branch = data["EMPLOYEE_OF_THE_MONTH"][0]["BRANCH_NAME"];
        this.EOM_Image = data["EMPLOYEE_OF_THE_MONTH"][0]["PROFILE_PHOTO"] ? this.api.retriveimgUrl + "profilePhoto/" + data["EMPLOYEE_OF_THE_MONTH"][0]["PROFILE_PHOTO"] : "assets/anony.png";
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }
}
