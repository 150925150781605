import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ExportDirective } from './directives/export.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component'
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData, AsyncPipe, DatePipe } from '@angular/common';
import en from '@angular/common/locales/en';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ApplicationComponent } from './pages/CommonModule/application/application/application.component';
import { ApplicationsComponent } from './pages/CommonModule/application/applications/applications.component';
import { ModuleComponent } from './pages/CommonModule/module/module/module.component';
import { ModulesComponent } from './pages/CommonModule/module/modules/modules.component';
import { UserComponent } from './pages/CommonModule/user/user/user.component';
import { UsersComponent } from './pages/CommonModule/user/users/users.component';
import { ApplicationModuleMappingComponent } from './pages/CommonModule/application/application-module-mapping/application-module-mapping.component';
import { UserModuleMappingComponent } from './pages/CommonModule/user/user-module-mapping/user-module-mapping.component';
import { DashboardComponent } from './pages/CommonModule/Dashboard/dashboard/dashboard.component';
import { RolesComponent } from './pages/CommonModule/role/roles/roles.component';
import { RoleComponent } from './pages/CommonModule/role/role/role.component';
import { RolemodulemappingComponent } from './pages/CommonModule/role/rolemodulemapping/rolemodulemapping.component';
import { FaqHeadsComponent } from './pages/SupportModule/FaqHead/faq-heads/faq-heads.component'
import { FaqHeadComponent } from './pages/SupportModule/FaqHead/faq-head/faq-head.component'
import { FaqsComponent } from './pages/SupportModule/FAQ/faqs/faqs.component';
import { FaqComponent } from './pages/SupportModule/FAQ/faq/faq.component';
import { FormsComponent } from './pages/CommonModule/form/forms/forms.component'
import { FormComponent } from './pages/CommonModule/form/form/form.component'
import { SelectpageComponent } from './pages/CommonModule/selectpage/selectpage/selectpage.component';
import { RoledetailsComponent } from './pages/CommonModule/role/roledetails/roledetails.component';
import { FaqresponsesComponent } from './pages/SupportModule/FAQ/faqresponses/faqresponses.component';
import { DepartmentComponent } from './pages/SupportModule/Department/department/department.component';
import { DepartmentsComponent } from './pages/SupportModule/Department/departments/departments.component';
// import { DepartmentcalenderComponent } from './pages/SupportModule/Department/departmentcalender/departmentcalender/departmentcalender.component';
import { EmailComponent } from './pages/CommunicationModule/EmailServer/email/email.component';
import { EmailsComponent } from './pages/CommunicationModule/EmailServer/emails/emails.component';
import { HistoryComponent } from './pages/CommunicationModule/EmailServer/history/history.component';
import { SMSHistoryComponent } from './pages/CommunicationModule/SMSServer/history/SMShistory.component';
import { SmsComponent } from './pages/CommunicationModule/SMSServer/sms/sms.component';
import { SmssComponent } from './pages/CommunicationModule/SMSServer/smss/smss.component';
import { FolderComponent } from './pages/DocumentModule/folder/folder/folder.component';
import { FoldersComponent } from './pages/DocumentModule/folder/folders/folders.component';
import { TicketsComponent } from './pages/SupportModule/Tickets/tickets/tickets.component';
import { TicketdetailsComponent } from './pages/SupportModule/Tickets/ticketdetails/ticketdetails.component';
import { UserapplicationmappingComponent } from './pages/CommonModule/user/userapplicationmapping/userapplicationmapping.component';
import { UsermodulemappingComponent } from './pages/CommonModule/user/usermodulemapping/usermodulemapping.component';
import { UseraccessmappingComponent } from './pages/CommonModule/user/useraccessmapping/useraccessmapping.component';
import { UserdepartmentmappingComponent } from './pages/CommonModule/user/userdepartmentmapping/userdepartmentmapping.component';
import { UserrolemappingComponent } from './pages/CommonModule/user/userrolemapping/userrolemapping.component';
import { TaskComponent } from './pages/SchedularModule/Task/task/task.component';
import { TasksComponent } from './pages/SchedularModule/Task/tasks/tasks.component';
import { ViewhistoryComponent } from './pages/SchedularModule/History/viewhistory/viewhistory.component';
import { LogmoduleComponent } from './pages/LogModule/logmodule/logmodule.component';
import { TicketgroupComponent } from './pages/SupportModule/Tickets/ticketgroup/ticketgroup.component';
import { TicketgroupsComponent } from './pages/SupportModule/Tickets/ticketgroups/ticketgroups.component';
import { TestsComponent } from './pages/tests/tests.component';
import { ViewbodyComponent } from './pages/CommunicationModule/EmailServer/viewbody/viewbody.component';
import { TestComponent } from './pages/CommonModule/test/test.component';
import { MyticketComponent } from './pages/UserModule/myticket/myticket.component';
import { CreateticketComponent } from './pages/UserModule/createticket/createticket.component';
import { ViewchatticketComponent } from './pages/UserModule/viewchatticket/viewchatticket.component';
import { TicketreportComponent } from './pages/Reports/ticketreport/ticketreport.component';
import { SummaryreportComponent } from './pages/Reports/summaryreport/summaryreport.component';
import { UserwisesummaryComponent } from './pages/Reports/userwisesummary/userwisesummary.component';
import { DashboardreportComponent } from './pages/Report/dashboardreport/dashboardreport.component';
import { AwsbulksenderComponent } from './pages/CommunicationModule/aws/awsbulksender/awsbulksender.component';
import { AwstemplateComponent } from './pages/CommunicationModule/aws/awstemplate/awstemplate.component';
import { AwsemailsComponent } from './pages/CommunicationModule/aws/awsemails/awsemails.component';
import { AwstemplatesComponent } from './pages/CommunicationModule/aws/awstemplates/awstemplates.component';
import { ChattdetailsicketComponent } from './pages/Reports/chattdetailsicket/chattdetailsicket.component';
import { OrganizationComponent } from './pages/Organizations/organization/organization.component';
import { OrganizationsComponent } from './pages/Organizations/organizations/organizations.component';
import { BranchesComponent } from './pages/BranchMaster/branches/branches.component';
import { BranchComponent } from './pages/BranchMaster/branch/branch.component';
import { DesignationComponent } from './pages/DesignationMaster/designation/designation.component';
import { DesignationsComponent } from './pages/DesignationMaster/designations/designations.component';
// import { AwsbulksenderComponent } from './pages/CommunicationModule/aws/awsbulksender/awsbulksender.component';
// import { AwsemailsComponent } from './pages/CommunicationModule/aws/awsemails/awsemails.component';
// import { AwstemplateComponent } from './pages/CommunicationModule/aws/awstemplate/awstemplate.component';
// import { AwstemplatesComponent } from './pages/CommunicationModule/aws/awstemplates/awstemplates.component';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { TickRoleComponent } from './pages/RoleMaster/tick-role/tick-role.component';
import { TickRolesComponent } from './pages/RoleMaster/tick-roles/tick-roles.component';
import { FeatureComponent } from './pages/FeatureMaster/feature/feature.component';
import { FeaturesComponent } from './pages/FeatureMaster/features/features.component';
import { HelploginComponent } from './helplogin/helplogin.component';
// import { Clusterbranchmasterlistcomponent } from './pages/clusterbranchmaster/clusterbranchmasterlist/clusterbranchmasterlist.component';
// import { AddclusterbranchmasterComponent } from './pages/clusterbranchmaster/addclusterbranchmaster/addclusterbranchmaster.component';
import { EmployeeMaster } from './Models/employeemaster';
import { AddemployeemasterComponent } from './pages/employeemaster/addemployeemaster/addemployeemaster.component';
import { EmployeemasterlistComponent } from './pages/employeemaster/employeemasterlist/employeemasterlist.component';
import { ClustermasterlistComponent } from './pages/clustermaster/clustermasterlist/clustermasterlist.component';
import { AddclustermasterComponent } from './pages/clustermaster/addclustermaster/addclustermaster.component';
import { AddemployeerolemapComponent } from './pages/employeerolemap/addemployeerolemap/addemployeerolemap.component';
import { EmployeerolemaplistComponent } from './pages/employeerolemap/employeerolemaplist/employeerolemaplist.component';
import { EmploginComponent } from './emplogin/emplogin.component';
import { SearchfaqComponent } from './pages/UserModule/searchfaq/searchfaq.component';
import { FolderMasterComponent } from './pages/FolderAndFileModule/Folder/folder-master/folder-master.component';
import { FolderDrawerComponent } from './pages/FolderAndFileModule/Folder/folder-drawer/folder-drawer.component';
import { FileDrawerComponent } from './pages/FolderAndFileModule/Folder/file-drawer/file-drawer.component';

import { SharedFolderMasterComponent } from './pages/FolderAndFileModule/SharedFolder/shared-folder-master/shared-folder-master.component';
import { TransferTicketDrawerComponent } from './pages/SupportModule/Tickets/transfer-ticket-drawer/transfer-ticket-drawer.component';
import { FaqresponsereportComponent } from './pages/Reports/faqresponsereport/faqresponsereport.component';
import { MynotificationsComponent } from './pages/Notify/mynotifications/mynotifications.component';
import { SendnotificationsComponent } from './pages/Notify/sendnotifications/sendnotifications.component';
import { SharingExistingFolderComponent } from './pages/FolderAndFileModule/Folder/sharing-existing-folder/sharing-existing-folder.component';
import { ViewNotificationDrawerComponent } from './pages/NotificationModule/view-notification-drawer/view-notification-drawer.component';
import { AddNewNotificationDrawerComponent } from './pages/NotificationModule/add-new-notification-drawer/add-new-notification-drawer.component';
import { OptionreportComponent } from './pages/Reports/optionreport/optionreport.component';
import { SendEmailDrawerComponent } from './pages/NotificationModule/send-email-drawer/send-email-drawer.component';
import { ChangePasswordDrawerComponent } from './pages/CommonModule/change-password-drawer/change-password-drawer.component';
import { LeavetypesComponent } from './pages/EMM_Module/LeaveType/leavetypes/leavetypes.component';
import { LeavetypeComponent } from './pages/EMM_Module/LeaveType/leavetype/leavetype.component';
import { AddEmployeeLeaveComponent } from './pages/EMM_Module/leave/add-employee-leave/add-employee-leave.component';
import { ExpenseheadComponent } from './pages/EMM_Module/ExpenseHead/expensehead/expensehead.component';
import { ExpensesheadComponent } from './pages/EMM_Module/ExpenseHead/expenseshead/expenseshead.component';
import { AddEmployeeExpensesComponent } from './pages/EMM_Module/expenses/add-employee-expenses/add-employee-expenses.component';
import { EvaluationcriteriaComponent } from './pages/EMM_Module/EvaluationCriteria/evaluationcriteria/evaluationcriteria.component';
import { EvaluationcriteriasComponent } from './pages/EMM_Module/EvaluationCriteria/evaluationcriterias/evaluationcriterias.component';
import { CustomersComponent } from './pages/EMM_Module/Customer/customers/customers.component';
import { CustomerComponent } from './pages/EMM_Module/Customer/customer/customer.component';
import { HolidaysComponent } from './pages/EMM_Module/Holidays/holidays/holidays.component';
import { ThoughsComponent } from './pages/EMM_Module/Though/thoughs/thoughs.component';
import { ThoughComponent } from './pages/EMM_Module/Though/though/though.component';
import { BirthdaysComponent } from './pages/EMM_Module/birthday/birthdays/birthdays.component';
import { BirthdayComponent } from './pages/EMM_Module/birthday/birthday/birthday.component';
import { AddearlymarkComponent } from './pages/EMM_Module/Earlymark/addearlymark/addearlymark.component';
import { AddlatemarkComponent } from './pages/EMM_Module/LateMark/addlatemark/addlatemark.component';
import { EmployeeexpensetypemappingComponent } from './pages/employeemaster/employeeexpensetypemapping/employeeexpensetypemapping.component';
import { LeaveApproveDrawerComponent } from './pages/EMM_Module/leave/leave-approve-drawer/leave-approve-drawer.component';
import { HeadLeaveApproveDrawerComponent } from './pages/EMM_Module/leave/head-leave-approve-drawer/head-leave-approve-drawer.component';
import { HeadLatemarkApproveDrawerComponent } from './pages/EMM_Module/LateMark/head-latemark-approve-drawer/head-latemark-approve-drawer.component';
import { HeadEarlymarkApproveDrawerComponent } from './pages/EMM_Module/Earlymark/head-earlymark-approve-drawer/head-earlymark-approve-drawer.component';
import { EmployeecollegemappingComponent } from './pages/employeemaster/employeecollegemapping/employeecollegemapping.component';
import { AccountantExpensesApproveDrawerComponent } from './pages/EMM_Module/expenses/accountant-expenses-approve-drawer/accountant-expenses-approve-drawer.component';
import { HeadExpensesApproveDrawerComponent } from './pages/EMM_Module/expenses/head-expenses-approve-drawer/head-expenses-approve-drawer.component';
import { AttendancereportComponent } from './pages/EMM_Module/Reports/attendancereport/attendancereport.component';
import { LeavereportComponent } from './pages/EMM_Module/Reports/leavereport/leavereport.component';
import { LatemarkreportComponent } from './pages/EMM_Module/Reports/latemarkreport/latemarkreport.component';
import { EarlymarkreportComponent } from './pages/EMM_Module/Reports/earlymarkreport/earlymarkreport.component';
import { ExpensereportComponent } from './pages/EMM_Module/Reports/expensereport/expensereport.component';
import { EvaluationreportComponent } from './pages/EMM_Module/Reports/evaluationreport/evaluationreport.component';
import { AssetCategoryListComponent } from './pages/materialIssueModule/assetCategory/asset-category-list/asset-category-list.component';
import { AssetCategoryDrawerComponent } from './pages/materialIssueModule/assetCategory/asset-category-drawer/asset-category-drawer.component';
import { AssetListComponent } from './pages/materialIssueModule/asset/asset-list/asset-list.component';
import { AssetDrawerComponent } from './pages/materialIssueModule/asset/asset-drawer/asset-drawer.component';
import { EarlymarkTilesComponent } from './pages/EMM_Module/Earlymark/earlymark-tiles/earlymark-tiles.component';
import { LatemarkTilesComponent } from './pages/EMM_Module/LateMark/latemark-tiles/latemark-tiles.component';
import { LeaveTilesComponent } from './pages/EMM_Module/leave/leave-tiles/leave-tiles.component';
import { ExpensesTilesComponent } from './pages/EMM_Module/expenses/expenses-tiles/expenses-tiles.component';
import { HeadApproveEmployeeLeavesTilesComponent } from './pages/EMM_Module/leave/head-approve-employee-leaves-tiles/head-approve-employee-leaves-tiles.component';
import { ApproveEmployeeLeavesTilesComponent } from './pages/EMM_Module/leave/approve-employee-leaves-tiles/approve-employee-leaves-tiles.component';
import { HeadApproveEmployeeLatemarkTilesComponent } from './pages/EMM_Module/LateMark/head-approve-employee-latemark-tiles/head-approve-employee-latemark-tiles.component';
import { HeadApproveEmployeeEarlymarkTilesComponent } from './pages/EMM_Module/Earlymark/head-approve-employee-earlymark-tiles/head-approve-employee-earlymark-tiles.component';
import { ExpensesApproveFromHeadTilesComponent } from './pages/EMM_Module/expenses/expenses-approve-from-head-tiles/expenses-approve-from-head-tiles.component';
import { ExpensesApproveFromAccountantTilesComponent } from './pages/EMM_Module/expenses/expenses-approve-from-accountant-tiles/expenses-approve-from-accountant-tiles.component';
import { AssetMappingListComponent } from './pages/materialIssueModule/assetMapping/asset-mapping-list/asset-mapping-list.component';
import { AssetMappingDrawerComponent } from './pages/materialIssueModule/assetMapping/asset-mapping-drawer/asset-mapping-drawer.component';
import { AssetRequestListComponent } from './pages/materialIssueModule/assetRequest/asset-request-list/asset-request-list.component';
import { AssetRequestDrawerComponent } from './pages/materialIssueModule/assetRequest/asset-request-drawer/asset-request-drawer.component';
import { AttendanceregisterComponent } from './pages/EMM_Module/Reports/attendanceregister/attendanceregister.component';
import { UpdateAttendanceRegisterComponent } from './pages/EMM_Module/Reports/update-attendance-register/update-attendance-register.component';
import { TrainingsublistComponent } from './pages/TrainingModule/trainingsubmaster/trainingsublist/trainingsublist.component';
import { AddtrainingsubComponent } from './pages/TrainingModule/trainingsubmaster/addtrainingsub/addtrainingsub.component';
import { TrainermasterlistComponent } from './pages/TrainingModule/trainermaster/trainermasterlist/trainermasterlist.component';
import { AddtrainermasterComponent } from './pages/TrainingModule/trainermaster/addtrainermaster/addtrainermaster.component';
import { TrainingtypelistComponent } from './pages/TrainingModule/trainingtypemaster/trainingtypelist/trainingtypelist.component';
import { AddtrainingtypeComponent } from './pages/TrainingModule/trainingtypemaster/addtrainingtype/addtrainingtype.component';
import { AnnualtrainpointlistComponent } from './pages/TrainingModule/annualtrainpoint/annualtrainpointlist/annualtrainpointlist.component';
import { AddannualtrainpointComponent } from './pages/TrainingModule/annualtrainpoint/addannualtrainpoint/addannualtrainpoint.component';
import { TrainingtrainerMapComponent } from './pages/TrainingModule/annualtrainpoint/trainingtrainermap/trainingtrainermap.component';
import { TrainingpointmasterlistComponent } from './pages/TrainingModule/trainingpointmaster/trainingpointmasterlist/trainingpointmasterlist.component';
import { AddtrainingpointmasterComponent } from './pages/TrainingModule/trainingpointmaster/addtrainingpointmaster/addtrainingpointmaster.component';
import { TrainingschedulelistComponent } from './pages/TrainingModule/trainingschedule/trainingschedulelist/trainingschedulelist.component';
import { AddtrainingscheduleComponent } from './pages/TrainingModule/trainingschedule/addtrainingschedule/addtrainingschedule.component';
import { TrainattendmapComponent } from './pages/TrainingModule/trainingschedule/trainattendmap/trainattendmap.component';
import { InductionarealistComponent } from './pages/TrainingModule/inductionareamaster/inductionarealist/inductionarealist.component';
import { AddinductionareaComponent } from './pages/TrainingModule/inductionareamaster/addinductionarea/addinductionarea.component';
import { TrainfeedformmasterlistComponent } from './pages/TrainingModule/trainingfeedbackformmaster/trainfeedformmasterlist/trainfeedformmasterlist.component';
import { AddtrainfeedformmasterComponent } from './pages/TrainingModule/trainingfeedbackformmaster/addtrainfeedformmaster/addtrainfeedformmaster.component';
import { TrainingevalutionformlistComponent } from './pages/TrainingModule/trainingevalutionform/trainingevalutionformlist/trainingevalutionformlist.component';
import { AddtrainingevalutionformComponent } from './pages/TrainingModule/trainingevalutionform/addtrainingevalutionform/addtrainingevalutionform.component';
import { TrainfeedmasterlistComponent } from './pages/TrainingModule/trainingfeedbackmaster/trainfeedmasterlist/trainfeedmasterlist.component';
import { AddtrainfeedmasterComponent } from './pages/TrainingModule/trainingfeedbackmaster/addtrainfeedmaster/addtrainfeedmaster.component';
import { TrainattendancecardlistComponent } from './pages/TrainingModule/trainattendancecard/trainattendancecardlist/trainattendancecardlist.component';
import { AddtrainattendancecardComponent } from './pages/TrainingModule/trainattendancecard/addtrainattendancecard/addtrainattendancecard.component';
import { AttendcardComponent } from './pages/TrainingModule/trainattendancecard/attendcard/attendcard.component';
import { TrainerloginviewComponent } from './pages/TrainingModule/trainerlogin/trainerloginview/trainerloginview.component';
import { TrainerloginviewdrawerComponent } from './pages/TrainingModule/trainerlogin/trainerloginviewdrawer/trainerloginviewdrawer.component';
import { AccessorviewComponent } from './pages/TrainingModule/accessor/accessorview/accessorview.component';
import { AccessorviewdrawerComponent } from './pages/TrainingModule/accessor/accessorviewdrawer/accessorviewdrawer.component';
import { AssetRegisterComponent } from './pages/materialIssueModule/Reports/asset-register/asset-register.component';
import { AssetAllocationRegisterComponent } from './pages/materialIssueModule/Reports/asset-allocation-register/asset-allocation-register.component';
import { AssetMaintenanceRegisterComponent } from './pages/materialIssueModule/Reports/asset-maintenance-register/asset-maintenance-register.component';
import { AssetCategoryRegisterComponent } from './pages/materialIssueModule/Reports/asset-category-register/asset-category-register.component';
import { AssetRegisterForAssetCategoryRegisterComponent } from './pages/materialIssueModule/Reports/asset-register-for-asset-category-register/asset-register-for-asset-category-register.component';
import { NgxPrintModule } from 'ngx-print';
import { EmployeeOfTheMonthComponent } from './pages/EMM_Module/employee-of-the-month/employee-of-the-month.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    TestComponent,
    // AwsbulksenderComponent,
    // AwsemailsComponent,
    // AwstemplateComponent,
    // AwstemplatesComponent,
    TransferTicketDrawerComponent,
    LoginComponent,
    ApplicationComponent,
    ApplicationsComponent,
    ModuleComponent,
    ModulesComponent,
    UserComponent,
    UsersComponent,
    ApplicationModuleMappingComponent,
    UserModuleMappingComponent,
    DashboardComponent,
    RolesComponent,
    RoleComponent,
    RolemodulemappingComponent,
    FaqHeadsComponent,
    FaqHeadComponent,
    FaqsComponent,
    FaqComponent,
    FormsComponent,
    FormComponent,
    RoledetailsComponent,
    SelectpageComponent,
    FaqresponsesComponent,
    DepartmentComponent,
    DepartmentsComponent,
    // DepartmentcalenderComponent,
    EmailComponent,
    EmailsComponent,
    HistoryComponent,
    SmsComponent,
    SmssComponent,
    FolderComponent,
    FoldersComponent,
    TicketsComponent,
    TicketdetailsComponent,
    UserapplicationmappingComponent,
    UsermodulemappingComponent,
    UseraccessmappingComponent,
    UserdepartmentmappingComponent,
    UserrolemappingComponent,
    TaskComponent,
    TasksComponent,
    ViewhistoryComponent,
    LogmoduleComponent,
    TicketgroupComponent,
    TicketgroupsComponent,
    SMSHistoryComponent,
    ExportDirective,
    TestsComponent,
    ViewbodyComponent,
    MyticketComponent,
    CreateticketComponent,
    ViewchatticketComponent,
    TicketreportComponent,
    SummaryreportComponent,
    UserwisesummaryComponent,
    DashboardreportComponent,
    AwsbulksenderComponent,
    AwstemplateComponent,
    AwsemailsComponent,
    AwstemplatesComponent,
    ChattdetailsicketComponent,
    OrganizationComponent,
    OrganizationsComponent,
    BranchesComponent,
    BranchComponent,
    DesignationComponent,
    DesignationsComponent,
    TickRoleComponent,
    TickRolesComponent,
    FeatureComponent,
    FeaturesComponent,
    HelploginComponent,
    ClustermasterlistComponent,
    AddclustermasterComponent,
    EmployeemasterlistComponent,
    AddemployeemasterComponent,
    AddemployeerolemapComponent,
    EmployeerolemaplistComponent,
    EmploginComponent,
    SearchfaqComponent,
    FolderMasterComponent,
    FolderDrawerComponent,
    FileDrawerComponent,
    SharedFolderMasterComponent,
    FaqresponsereportComponent,
    FolderMasterComponent,
    FolderDrawerComponent,
    FileDrawerComponent,
    SharedFolderMasterComponent,
    TransferTicketDrawerComponent,
    MynotificationsComponent,
    SendnotificationsComponent,
    FolderMasterComponent,
    FolderDrawerComponent,
    FileDrawerComponent,
    SharedFolderMasterComponent,
    TransferTicketDrawerComponent,
    SharingExistingFolderComponent,
    ViewNotificationDrawerComponent,
    AddNewNotificationDrawerComponent,
    OptionreportComponent,
    SendEmailDrawerComponent,
    ChangePasswordDrawerComponent,
    LeavetypesComponent,
    LeavetypeComponent,
    AddEmployeeLeaveComponent,
    ExpenseheadComponent,
    ExpensesheadComponent,
    AddEmployeeExpensesComponent,
    EvaluationcriteriaComponent,
    EvaluationcriteriasComponent,
    CustomersComponent,
    CustomerComponent,
    HolidaysComponent,
    ThoughsComponent,
    ThoughComponent,
    BirthdaysComponent,
    BirthdayComponent,
    AddearlymarkComponent,
    AddlatemarkComponent,
    EmployeeexpensetypemappingComponent,
    LeaveApproveDrawerComponent,
    HeadLeaveApproveDrawerComponent,
    HeadLatemarkApproveDrawerComponent,
    HeadEarlymarkApproveDrawerComponent,
    EmployeecollegemappingComponent,
    AccountantExpensesApproveDrawerComponent,
    HeadExpensesApproveDrawerComponent,
    AttendancereportComponent,
    LeavereportComponent,
    LatemarkreportComponent,
    EarlymarkreportComponent,
    ExpensereportComponent,
    EvaluationreportComponent,
    EarlymarkTilesComponent,
    LatemarkTilesComponent,
    LeaveTilesComponent,
    ExpensesTilesComponent,
    HeadApproveEmployeeLeavesTilesComponent,
    ApproveEmployeeLeavesTilesComponent,
    HeadApproveEmployeeLatemarkTilesComponent,
    HeadApproveEmployeeEarlymarkTilesComponent,
    ExpensesApproveFromHeadTilesComponent,
    ExpensesApproveFromAccountantTilesComponent,
    AttendanceregisterComponent,
    UpdateAttendanceRegisterComponent,
    AssetCategoryListComponent,
    AssetCategoryDrawerComponent,
    AssetListComponent,
    AssetDrawerComponent,
    AssetMappingListComponent,
    AssetMappingDrawerComponent,
    AssetRequestListComponent,
    AssetRequestDrawerComponent,
    TrainingsublistComponent,
    AddtrainingsubComponent,
    TrainermasterlistComponent,
    AddtrainermasterComponent,
    TrainingtypelistComponent,
    AddtrainingtypeComponent,
    AnnualtrainpointlistComponent,
    AddannualtrainpointComponent,
    TrainingtrainerMapComponent,
    TrainingpointmasterlistComponent,
    AddtrainingpointmasterComponent,
    TrainingschedulelistComponent,
    AddtrainingscheduleComponent,
    TrainattendmapComponent,
    InductionarealistComponent,
    AddinductionareaComponent,
    TrainfeedformmasterlistComponent,
    AddtrainfeedformmasterComponent,
    TrainingevalutionformlistComponent,
    AddtrainingevalutionformComponent,
    TrainfeedmasterlistComponent,
    AddtrainfeedmasterComponent,
    TrainattendancecardlistComponent,
    AddtrainattendancecardComponent,
    AttendcardComponent,
    TrainerloginviewComponent,
    TrainerloginviewdrawerComponent,
    AccessorviewComponent,
    AccessorviewdrawerComponent,
    AssetRegisterComponent,
    AssetAllocationRegisterComponent,
    AssetMaintenanceRegisterComponent,
    AssetCategoryRegisterComponent,
    AssetRegisterForAssetCategoryRegisterComponent,
    EmployeeOfTheMonthComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsProviderModule,
    NgZorroAntdModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    NzSwitchModule,
    NgxPrintModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],

  providers: [{ provide: NZ_I18N, useValue: en_US }, CookieService, AsyncPipe, DatePipe],
  bootstrap: [AppComponent]
})

export class AppModule { }
